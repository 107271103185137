<template>
    <div>
        <h3>Roadmap Webinar Registrants</h3>
        <br/>
        <vs-card v-if="logged_in_spoc_id == 132 || logged_in_spoc_id == 100 || logged_in_spoc_id == 937 || logged_in_spoc_id == 39">
        <vs-row>
            <vs-col style="font-size: 14px" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1"><b>Webinar
                List : </b></vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
                <div>
                    <treeselect
                    :multiple="true"
                    :options="webinars_list"
                    placeholder="Search by webinar topic"
                    v-model="selected_webinar"
                    />
                </div> 
            </vs-col>
            <vs-col vs-w="2"></vs-col>
            <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="1.5">
                <vs-button @click="viewFilter()">View Filter</vs-button> 
            </vs-col>
            <vs-col  vs-type="flex" vs-justify="left" vs-align="center" vs-w="1.5">
                <vs-button @click="setFilter()">Set Filter</vs-button>    
            </vs-col>
        </vs-row>
        </vs-card>
        <vs-card style="margin-top: 1%">
        <vs-table stripe :data="f2f_details"  v-if="cities.length>0">
            <template slot="thead">
                <vs-th> City Class</vs-th>
                <vs-th> GM </vs-th>
                <vs-th v-for="(table_title, index) in table_titles" :key="`city-heading-${index}`">
                    {{ table_title.topic }} <br />
                    {{ getMomentDateFromTimestamp(table_title.date) }}
                </vs-th>
            </template>

            <template>
                <vs-tr v-for="(city_classification, index) in city_classifications" :key="`cc-${index}`">
                    <vs-td>
                        {{ city_classification.cc }}
                    </vs-td>
                    <vs-td>
                        {{ city_classification.gm }}
                    </vs-td>

                    <vs-td v-for="(city, index) in Object.keys(table_titles)" :key="`city-${index}`">
                        <div style="color: #9666f6"
                            @click="getDetails(city_classification.gm,city,matrix[city_classification.gm][city].count)">
                            {{
                            matrix[city_classification.gm][city] === undefined
                            ? 0
                            : matrix[city_classification.gm][city].count
                            }}

                            <!-- {{ matrix[gm][webinar.webinar_id]}} -->
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        </vs-card>
        <!-- Spoc Count -->
        <vs-card style="margin-top: 5%" v-if="f2f_spoc_details.length > 0">
            <div slot="header">
                <h5>
                    {{ pass_gm_name }} - {{pass_webinar_topic}} : [ {{pass_total_count}} ]
                </h5>
            </div>
            <vs-table stripe :data="f2f_spoc_details" max-items="10" pagination>
                <template slot="thead">
                    <vs-th> City Class </vs-th>
                    <vs-th> Spoc Name </vs-th>
                    <vs-th> Count </vs-th>
                    <vs-th> Webinar Date </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].candidate_id">
                            <span v-if="data[indextr].city_classification == null"> NA</span>
                            <span
                                v-if="data[indextr].city_classification !== null">{{data[indextr].city_classification}}</span>
                        </vs-td>

                        <vs-td :data="data[indextr].spoc_name">
                            <div v-if="data[indextr].spoc_name !== ''"> {{ data[indextr].spoc_name }} </div>
                            <div v-if="data[indextr].spoc_name == ''">Unknown Spoc</div>
                        </vs-td>

                        <vs-td :data="data[indextr].count" style="color: #9666f6">
                            <div
                                v-on:click="getSpocDetails(data[indextr].spoc_id,data[indextr].spoc_name,data[indextr].count)">
                                {{
                                data[indextr].count }}</div>
                        </vs-td>

                        <vs-td :data="data[indextr].webinar_id">
                            {{ getSpocWebinarDate(pass_webinar_date) }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-card>

        <vs-card style="margin-top: 5%" v-if="spoc_candidate_details.length > 0">
            <div slot="header">
                <h5>{{ pass_sopc_name }} - {{pass_webinar_topic}} : [ {{pass_spoc_count}} ] </h5>
            </div>
            <vs-table stripe :data="spoc_candidate_details" max-items="10" pagination>
                <template slot="thead">
                    <vs-th> Can-ID </vs-th>
                    <vs-th> Name </vs-th>
                    <vs-th> Level </vs-th>
                    <vs-th> City </vs-th>
                    <vs-th> Course </vs-th>
                    <vs-th> Webinar Date </vs-th>
                    <vs-th> Last Call </vs-th>
                    <vs-th> Action </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].candidate_id">
                            {{ data[indextr].candidate_id }}
                        </vs-td>

                        <vs-td :data="data[indextr].candidate_name">
                            {{ data[indextr].candidate_name }}
                        </vs-td>
                        <vs-td :data="data[indextr].level">
                            {{ data[indextr].level }}
                        </vs-td>
                        <vs-td :data="data[indextr].candidate_id">
                            {{ data[indextr].city }}
                        </vs-td>

                        <vs-td :data="data[indextr].candidate_id">
                            {{ data[indextr].course }}
                        </vs-td>

                        <vs-td :data="data[indextr].webinar_id">
                            {{ getMomentDateFromTimestamp(pass_webinar_date) }}
                        </vs-td>
                        <vs-td :data="data[indextr].last_call">
                            {{ getSpoclastCallDate(data[indextr].last_call) }}
                            <!-- {{ data[indextr].last_call }} -->
                        </vs-td>

                        <vs-td :data="data[indextr].candidate_id">
                            <div @click="actionData(data[indextr].mwb_id)">
                                <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-card>
    </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import constants from "../../../constants.json";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
    components: {
        Treeselect,
        "v-select": vSelect,
    },
    data: () => ({
        selected_webinar: [],
        webinars_list: [],
        f2f_details: [],
        f2f_spoc_details: [],
        registrants_details: [],
        cities: [],
        webinars: [],
        table_titles: {},
        // dates: {},
        matrix: {},
        f2f_registrants_details: {},
        spoc_candidate_details: [],
        spoc_header_details: {},
        gms: [],
        pass_webinarid: null,
        pass_webinar_date: null,
        pass_sopc_name: "",
        pass_spoc_count: null,
        pass_gm_name: "",
        pass_webinar_topic: "",
        pass_total_count: null,
        logged_in_spoc_id: null,
    }),
    
    mounted() {
        this.logged_in_spoc_id = localStorage.getItem("spoc_id");
        this.fetchWebinars();
        this.getF2fRegistrant();
    },
    watch:{
        selected_webinar(val){
            console.log("selected_webinar qwe13",val);
        }   
    },
    methods: {
        setFilter(){
            console.log(this.selected_webinar);
            let url = `${constants.MILES_WEBINARS}setFilter`;
            let obj = {
                webinar_ids: this.selected_webinar.join()
            };
            axios
                .put(url, obj, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
                })
                .then((response) => {
                    console.log("setFilter", response.data)
                    if(response.data.status == "success"){
                        this.$vs.notify({
                        text: "Updated Successfully",
                        color: "success",
                    });    
                    }
                    this.getF2fRegistrant();
                });
        },
        viewFilter(){
            console.log(this.selected_webinar);
            let url = `${constants.MILES_WEBINARS}viewFilter`;
            let obj = {
                webinar_ids: this.selected_webinar.join()
            };
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
                })
                .then((response) => {
                    console.log("viewFilter", response.data)
                    
                    if(response.data.status == "success"){
                        this.$vs.notify({
                        text: response.data.message,
                        color: "success",
                        });   
                        this.f2f_details = response.data.webinar_stats;
                        this.processGMData(response.data.webinar_stats); 
                    } 
                    if(response.data.status == "error") {
                        this.$vs.notify({
                        text: response.data.message,
                        color: "danger",
                        });    
                    }
               
                });
        },
        fetchWebinars() {
            let url = `${constants.MILES_WEBINARS}getAllRoadmapWebinars`;
            axios
                .get(url,{
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
                })
                .then((response) => {
                console.log("getAllRoadmapWebinars", response.data)
                const webinars = [];
                response.data.forEach((w) => {
                    let webinar = w;
                    webinar.label = `${moment
                    .unix(webinar.start_time_unix)
                    .format("DD-MMM-YYYY")} : ${w.topic}`;
                    webinar.id = w.webinar_id;
                    webinars.push(webinar);
                });
                this.webinars_list = webinars;
                });
            console.log("this.webinars_list24",this.webinars_list);
        },
        getF2fRegistrant() {
            // this.$vs.loading();
            let url = `${constants.MILES_WEBINARS}roadmapWebinarStats`;
            axios.get(url, { headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                .then((response) => {
                    //  this.$vs.loading.close();
                    console.log("response -1", response);
                    this.f2f_details = response.data;
                    this.processGMData(response.data);
                })
                .catch((error) => {
                    alert(error)
                });
        },
        getMomentDateFromTimestamp(value) {
            var dateString = moment.unix(value).format("DD-MMM-YYYY");
            return dateString;
        },
        getWebinarDateFromTimestamp(value) {
            var dateString = moment.unix(value).format("DD-MMM-YYYY");
            return dateString;
        },
        getSpocWebinarDateFromTimestamp(value) {
            var dateString = moment.unix(value).format("DD-MMM-YYYY");
            return dateString;
        },
        getSpocWebinarDate(value) {
            var dateString = moment.unix(value).format("DD-MMM-YYYY");
            return dateString;
        },
        getSpoclastCallDate(value) {
            var dateString = moment.unix(value).format("DD-MMM-YYYY HH:mm");

            return dateString;
        },
        processGMData(data) {
            const matrix = {};
            const cities = [];
            const table_titles = {};
            const ccs = {};
            const ccs_array = [];

            // table heading
            data.forEach(ele => {
                let key = ele.webinar_id;
                if (!table_titles[key]) {
                    table_titles[key] = {
                        'topic': ele.topic,
                        'date': ele.start_time_unix
                    }
                }
            });
            console.log("table_titles", table_titles);
            // get list of cities
            data.forEach((city_gm_count) => {
                if (!cities.includes(city_gm_count.webinar_id)) {
                    cities.push(city_gm_count.webinar_id);
                }
            });

            // sort cities in ascending order
            cities.sort();
            console.log("cities", cities);

            data.forEach((city_gm_count) => {

                if (city_gm_count.gm_name === null) {
                    city_gm_count.gm_name = "Unknown GM";
                }
                if (city_gm_count.city_classification === null) {
                    // city_gm_count.city_classification = "NA-" + city_gm_count.gm_name.length + city_gm_count.gm_name.charAt(0);
                    city_gm_count.city_classification = "NA-" + city_gm_count.gm_name.match(/[A-Z]/g).join('').slice(0, 2)
                }

                if (ccs[city_gm_count.city_classification] === undefined) {
                    ccs[city_gm_count.city_classification] = city_gm_count.gm_name;
                }
                if (matrix[city_gm_count.gm_name] === undefined) {
                    matrix[city_gm_count.gm_name] = {};
                }

                matrix[city_gm_count.gm_name][city_gm_count.webinar_id] = {
                    count: city_gm_count.count,
                    city_classification: city_gm_count.city_classification,
                };
            });
            Object.keys(ccs).forEach((cc) => {
                ccs_array.push({
                    cc: cc,
                    gm: ccs[cc],
                });
            });

            const ccs_array_sorted = ccs_array.sort((a, b) => a.cc.localeCompare(b.cc));

            ccs_array_sorted.forEach((cc) => {
                const city_classification = cc.cc;
                const gm_name = cc.gm;
                let city_wise_data = [];
                cities.forEach((city) => {
                    if (matrix[gm_name][city] === undefined) {
                        city_wise_data.push("");
                    } else {
                        city_wise_data.push(matrix[gm_name][city].count);
                    }
                });
                console.log(`${city_classification} ${gm_name}  ${city_wise_data.toString()}`);
            });


            // this.dates = dates;
            this.cities = cities;
            this.matrix = matrix;
            this.table_titles = table_titles;
            this.selected_webinar = [];
            data.forEach((w) => {
                if(!this.selected_webinar.includes(w.webinar_id)){
                    this.selected_webinar.push(w.webinar_id);
                }
            });
            console.log("ccs_array_sorted", ccs_array_sorted);
            this.city_classifications = ccs_array_sorted;
        },
        getDetails(gm_name, webinar_id, count) {
            this.spoc_candidate_details = [];
            this.pass_webinarid = webinar_id
            this.pass_webinar_date = this.table_titles[webinar_id].date
            this.pass_gm_name = gm_name
            this.pass_webinar_topic = this.table_titles[webinar_id].topic
            this.pass_total_count = count
            console.log("pass_webinar_date", this.pass_webinar_date);
            this.getF2fStatsSpocData(gm_name, webinar_id);
        },
        getF2fStatsSpocData(gm_name, webinar_id) {
            if (gm_name == "Unknown GM") {
                gm_name = ""
            }
            this.f2f_registrants_details = {
                'gm_name': gm_name,
                'webinar_id': webinar_id,
            };
            this.$vs.loading();
            let url = `${constants.MILES_WEBINARS}roadmapSpocStats?gm_name=${gm_name}&webinar_id=${webinar_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.f2f_spoc_details = response.data;
                    console.log("Spoc Details", response);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
        },
        getSpocDetails(spoc_id, spoc_name, count) {
            this.pass_spoc_count = count
            this.pass_sopc_name = spoc_name
            if (spoc_id == null) {
                spoc_id = ''
            }
            this.getF2fStatsIndividualData(spoc_id, this.pass_webinarid);
            this.spoc_header_details = {
                spoc_id: spoc_id,
                webinar_id: this.pass_webinarid,
            };
        },
        getF2fStatsIndividualData(spoc_id, webinar_id) {
            this.$vs.loading();
            let url = `${constants.MILES_WEBINARS}roadmapIndividualStats?spoc_id=${spoc_id}&webinar_id=${webinar_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.spoc_candidate_details = response.data;
                    console.log("Spoc Candidate details ", response.data);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: `Error`,
                        text: error.response.data.message,
                        color: "danger",
                    });
                });
        },
        actionData(mwbId) {
            this.openBigPopup(mwbId);
        },
    },
};
</script>
  